import '../styles/templates/softwareDetails.scss'

import React from 'react'

import { PAGE_NAME } from '../../config/cms'
import eclipseTick from '../../static/assets/svg/eclipseTick.svg'
import requirementsIcon from '../../static/assets/svg/requirements-icon.svg'
import statisticsSVG from '../../static/assets/svg/statistician 1.svg'
import { CaseStudiesList } from '../components/case-studies-list'
import { IconButton } from '../components/icon-button'
import { ArrowIndicator } from '../components/icons/arrow-indicator'
import { Download } from '../components/icons/download'
import { Image } from '../components/image'
import { Layout } from '../components/layout'
import { useEntity } from '../hooks/cms/use-entity'
import { usePageData } from '../hooks/cms/use-page'
import type { Software } from '../hooks/cms/use-software'
import { useTags } from '../hooks/cms/use-tags'
import { PageComponentFactory } from '../utils/pages'

const getFilePath = file => `/assets/files/${file.split('/').pop()}`
const getFileNameWithExt = (fileName, file) =>
  `${fileName}.${file.split('/').pop().split('.').pop()}`

const SoftwareDetailsPage = PageComponentFactory(() => {
  const software: Software = useEntity()
  const tags = useTags()

  const { generalSection, benefitsSection, descriptionSection, requirements } =
    usePageData(PAGE_NAME.SoftwareDetails)
  const { strings } = usePageData(PAGE_NAME.CaseStudies)

  return (
    <Layout
      page={PAGE_NAME.SoftwareDetails}
      pathname={`/software/${software.identifier}`}
    >
      <main className="software-page-details">
        <section className="software-page-details__landing-section">
          <div className="container">
            <div className="software-page-details__grid-container">
              <div className="software-page-details__content">
                <h1>{software.name}</h1>
                <h2>{software.description}</h2>
                <ArrowIndicator />
              </div>
              <section className="software-page-details__image-container section">
                <img
                  src={statisticsSVG}
                  className="software-page-details__image"
                />
              </section>

              <section className="software-page-details__tag-list">
                <div>
                  <span className="software-page-details__tag-list-compability">
                    {generalSection.compability}
                  </span>
                  {software.tags.map((tag, index) => {
                    const title = tags.table.get(tag)?.title

                    return (
                      <span className="tag-element" key={index}>
                        {title}
                      </span>
                    )
                  })}
                </div>
              </section>
            </div>
          </div>
        </section>

        <section className="software-description-section section">
          <h3 className="software-description-section__description">
            {software.description}
          </h3>
          <h4 className="software-description-section__alt-description">
            {software.altDescription}
          </h4>

          <div className="software-description-section__download-container">
            <div className="software-description-section__more-info">
              {descriptionSection.moreInformation}
            </div>

            <div className="software-description-section__download-elements">
              {software?.files?.map(
                ({ displayedLabel, fileName, file }, index) => (
                  <IconButton
                    key={index}
                    href={getFilePath(file)}
                    className="software-description-section__download-el-spec"
                    variant="secondary"
                    target="_blank"
                    download={getFileNameWithExt(fileName, file)}
                    rel="noreferrer"
                  >
                    <>
                      {displayedLabel}
                      <Download />
                    </>
                  </IconButton>
                )
              )}
            </div>
          </div>
        </section>
        <section className="software-benefits-section section">
          <div className="software-benefits-section__benefits-list">
            <h3>{benefitsSection.benefits}</h3>
            {software?.benefits?.map((benefit, index) => (
              <span className="software-benefits-section__benefit" key={index}>
                <img
                  src={eclipseTick}
                  className="software-benefits-section__benefit-image"
                />
                <p className="software-benefits-section__benefit-text">
                  {benefit}
                </p>
              </span>
            ))}
          </div>

          <div className="software-benefits-section__image">
            <img
              src={
                software.media.contentImage &&
                getFilePath(software.media.contentImage)
              }
            />
          </div>
        </section>
        <section className="software-specification-section section">
          <h3>{software?.features?.heading}</h3>
          <div className="software-specification-section__content">
            {software?.features?.list.map(({ top, bottom, image }, index) => (
              <div
                className={`software-specification-section__content-feature ${
                  (index + 1) % 2 ? '' : 'even'
                }`}
                key={index}
              >
                <div className="software-specification-section__content-feature-image-container">
                  <Image path={image} />
                </div>

                <div className="software-specification-section__content-feature-caption-container">
                  <h4 className="software-specification-section__content-feature-name">
                    {top}
                  </h4>
                  <p className="software-specification-section__content-feature-detail">
                    {bottom}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="software-case-study-section section">
          <div className="software-requriments-section">
            <div className="software-requriments-section__hardware">
              <img src={requirementsIcon} />
              <div>
                <h4>{requirements.label}</h4>
                <h4>{software.requirements.hardwareRequirements}</h4>
              </div>
            </div>
            <ul className="software-requriments-section__software">
              {software.requirements.softwareRequirements.map(
                (requirement, index) => (
                  <li key={index}>
                    <h4>{requirement}</h4>
                  </li>
                )
              )}
            </ul>
          </div>

          <CaseStudiesList
            strings={strings}
            filtersEnabled={false}
            softwareId={software.identifier}
          />
        </section>
      </main>
    </Layout>
  )
})

export default SoftwareDetailsPage
